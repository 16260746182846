<template>
  <div class="blog-subscription">
    <div class="blog-subscription__image">
      <img src="~~/assets/images/right-watch-cat.png" alt="right-watch-cat" />
    </div>
    <form class="blog-subscription__body">
      <div class="blog-subscription__title-w">
        <p class="blog-subscription__title">
          {{ _T("@Blog subscription title") }}
        </p>
        <p class="blog-subscription__caption">
          {{ _T("@Blog subscription caption") }}
        </p>
        <InputEmail v-model="email" :validate="v" caption="E-mail" />
      </div>
      <ButtonPrimary
        type="submit"
        :aria-label="_T('@Subscribe')"
        button-width="236px"
        @click.prevent="onSubscribe"
      >
        {{ _T("@Subscribe") }}
      </ButtonPrimary>
    </form>
  </div>
</template>

<script setup>
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import { useAPI } from "~/uses/useMyFetch";
import { emailValidate, requiredValidate } from "~/utils/validators";
import useVuelidate from "@vuelidate/core";
import InputEmail from "~/modules/shared/inputs/InputEmail.vue";

const email = ref(null);

const rules = computed(() => ({
  required: requiredValidate(),
  email: emailValidate(),
}));

const v = useVuelidate(rules, email);

const onSubscribe = async () => {
  if (await v.value.$validate()) {
    useAPI("/marketing/event", {
      method: "POST",
      body: {
        event: "subscription",
        email: email.value,
        isConfirmed: false,
        params: {
          origin: "blog",
        },
      },
    }).then(() => {
      email.value = null;
      v.value.$reset();
    });
  }
};
</script>

<style scoped lang="scss">
.blog-subscription {
  @extend %width-main;

  @include flex-container(row, space-between, center);
  gap: 16px;

  border-radius: 32px;
  background-color: var(--color-blue-100);

  padding: 32px 32px 0;
  margin-inline: auto;

  @include bigMobile {
    flex-direction: column;

    padding-inline: 8px;
  }

  &__image {
    height: 160px;

    @include bigMobile {
      order: 1;
    }

    & > img {
      height: 100%;
    }
  }

  &__body {
    max-width: 940px;
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 16px;

    padding-bottom: 16px;

    @include bigMobile {
      flex-direction: column;
      padding-bottom: 0;
    }
  }

  &__title-w {
    width: 100%;
    max-width: 660px;

    @include flex-container(column, center, flex-start);
    gap: 16px;

    @include bigMobile {
      align-items: center;
      text-align: center;
    }
  }
}
</style>
